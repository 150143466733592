<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <div class="innovationheader">
      <Header/>
  </div>
      
<div class="container-fluid quotecontainer">
  <div class="container quotetext"> 
    <div id="heading-training">
      
    
      Inspiring and educating lawyers by developing  <span class="largetext">content, tools and resources </span>for lawyers

  
      </div>
    <div id="heading-team" class="hidden">
      Jacinta teams up with friends and colleagues to deliver <span class="largetext">interdisciplinary team training</span> that <br/>will help you launch or deepen your collaborative practice
    </div>
  </div>
</div><!--container-fluid-->



<div class="container-fluid gear">     
  <div class="row">
      <div class="col-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4 " style="text-align:center;">

      <br/><br/><br/><br/><br/><br/>     
      <img style="max-width:500px;" title="Innovation For Lawyers" alt="Innovation For Lawyers" src="../assets/images/IFL_logo.png">
      
      <br/><br/><br/><br/>

      <p> 
      Innovation for Lawyers was created by Jacinta Gallant, <br/>
      a practicing lawyer with a rewarding and enjoyable career in law. 
      Innovation for Lawyers aims to inspire, support and educate lawyers 
      to work with the vision and values that led them to law school,
      by developing content, tools and resources that lawyers can use
      to have successful careers and be happier going to work. 
      </p>
      <!-- <b>Innovation for Lawyers</b> was created by Jacinta Gallant, a practicing lawyer with a rewarding and enjoyable career in law.  Innovation for Lawyers aims to inspire, support and educate lawyers to work with the vision and values that led them to law school, by developing content, tools and resources that lawyers can use to have successful careers and be happier going to work.   </p> -->
      <p>
        <br/>
          <a style="border-radius:10px;padding:8px 10px; background-color:#ed9184;" class="gotobutton" href="https://www.innovateforlawyers.com/" target="_blank" title="Innovation For Lawyers">www.innovateforlawyers.com </a>
      </p>  
      <br/><br/><br/><br/><br/><br/><br/>

      <img src="../assets/images/books.png" style="margin-left:10px; margin-bottom:10px; max-width:500px;">

      <h2 style="font-size:45px;text-shadow:unset;font-weight: bold; color:#33749a;">OUR FAMILY IN <span style="color:#ed9184;">TWO HOMES</span></h2>
      <p>Our first product is the Our Family in Two Homes Resource Package,
      which provides lawyers with a highly effective way to engage with clients and families to work out separation agreements, 
      with a focus on the value that an innovative and highly skilled lawyer can bring to family law. 
      In a marketplace that seems to despise “divorce lawyers” this innovative product helps lawyers offer help and expertise
      where it is needed most, while supporting clients to reflect on the important decisions they need to make for their future.   
      </p>
      <br/><br/>
      <a class="gotobutton" style="border-radius:10px;padding:8px 10px; background-color:#ed9184;" href="https://www.intwohomes.com/" target="_blank" title="Our Family In Two Homes Site">www.intwohomes.com</a>
      
      <br/><br/><br/><br/><br/><br/><br/>
      </div>
          
  </div> 
  <div class="row">
    <div class="col-sm-4" style="height:20px; background-color:#f19f52;"></div>
    <div class="col-sm-4" style="height:20px; background-color:#50c0af;"></div>
    <div class="col-sm-4" style="height:20px; background-color:#f04d5e;"></div>
  </div>
      
  </div>
  <Footer/>
  </div>
</template>

<script>

// Components
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Innovation For Lawyers',
  components: {
    Header,
    Footer
  },
  setup() {
    const environment = process.env.VUE_APP_ENVIRONMENT

    return {
      environment
    }
  }
}
</script>

<style lang="css">
  .gear {
    padding-top:50px; 
    padding-bottom:0px;
    background: url(../assets/images/innovationbg.png) no-repeat;     
    background-size: 100%;background-position: center;
  }
</style>
